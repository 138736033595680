/**
 * Renders the Contact page
 *
 * @flow
 */
import React, { useState, useCallback } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import useEventListener from '@use-it/event-listener';

import type { WordPressPage } from '../types';
import Layout from '../layouts/DefaultLayout';
import SEO from '../components/SEO';
import { Container, Button } from '../styles';
import TextField from '../components/TextField';
import TextStyle from '../components/TextStyle';
import { homeUrl } from '../../utils';
import { paths } from '../../config';

const StyledTextField = styled(TextField)`
  input {
    width: 100%;
  }
`;

const StyledImg = styled(Img)``;

const StyledButton = styled(Button)`
  margin-top: 0.5em;
`;

const HeroSection = styled.div`
  margin-bottom: 99px;
  background: #ffffff;

  ${Container} {
    position: relative;
    padding-top: 39px;
    padding-bottom: 375px;
  }

  ${StyledImg} {
    position: absolute !important;
    bottom: -80px;
    left: 50%;
    width: 546px;
    height: 436px;
    transform: translateX(-50%);
  }

  h1 {
    margin-bottom: 15px;
  }

  @media (min-width: 768px) {
    margin-bottom: 144px;

    ${Container} {
      padding-top: 57px;
      padding-bottom: 584px;
    }

    ${StyledImg} {
      width: 900px;
      height: 718px;
      bottom: -120px;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 133px;

    ${Container} {
      padding-top: 94px;
      padding-bottom: 164px;
    }

    ${StyledImg} {
      top: -25px;
      left: 41%;
      width: 852px;
      height: 680px;
      transform: none;
    }
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 410px;
`;

const OfflineWarning = styled.div`
  padding: 4px;
`;

const getOfflineState = () => {
  if (
    typeof window !== 'undefined'
    && typeof navigator !== 'undefined'
    && typeof navigator.onLine !== 'undefined'
  ) {
    // Get offline state and listen for changes
    const [isOffline, setOffline] = useState(!navigator.onLine);

    useEventListener('online', () => {
      setOffline(false);
    });

    useEventListener('offline', () => {
      setOffline(true);
    });

    return isOffline;
  }

  // Fallback for static Gatsby build or older browsers
  return false;
};

const ContactForm = () => {
  const isOffline = getOfflineState();

  const handleSubmit = useCallback((/* event */) => {
    // Any extra validation can go here
  }, []);

  return (
    <form action="https://formspree.io/mnykbajx" method="post" onSubmit={handleSubmit}>
      <input type="text" name="_gotcha" style={{ display: 'none' }} />
      <input type="hidden" name="_next" value={homeUrl(`${paths.contact}?thanks`)} />
      <StyledTextField
        label="Your Name"
        name="name"
        pattern=".*\S+.*"
        required
      />
      <StyledTextField
        label="Your Email"
        type="email"
        name="_replyto"
        required
      />
      <StyledTextField
        label="Subject"
        name="_subject"
        pattern=".*\S+.*"
        required
      />
      <StyledTextField
        label="Your Message"
        name="message"
        multiline
        pattern=".*\S+.*"
        required
      />
      {isOffline && (
        <OfflineWarning>
          <TextStyle variation="negative">
            Uh oh! Looks like you are offline.
            You need an active internet connection to send this message.
          </TextStyle>
        </OfflineWarning>
      )}
      <StyledButton type="submit">Send</StyledButton>
    </form>
  );
};

type Props = {
  data: {
    page: WordPressPage,
    illustrationContact: {
      childImageSharp: {
        fluid: any,
      },
    },
  }
}

const Contact = ({
  data: {
    page,
    illustrationContact,
  },
}: Props) => {
  let showThanks = false;

  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('thanks') !== null) {
      window.localStorage.setItem('showThanks', true);
      navigate(paths.contact);
      return null;
    }

    if (window.localStorage.getItem('showThanks')) {
      showThanks = true;
      window.localStorage.removeItem('showThanks');
    }
  }

  return (
    <Layout fullwidth noHeaderBottomSpace>
      <SEO
        title={page.customMeta.title}
        canonical={page.customMeta.canonical}
        meta={page.yoastMeta}
      />

      <HeroSection>
        <Container>
          {showThanks && (
            <TextStyle variation="positive" as="p">
              Thank you for contacting us!
            </TextStyle>
          )}
          <HeroContent>
            {/* eslint-disable-next-line react/no-danger */}
            <h1 className="h1-plus" dangerouslySetInnerHTML={{ __html: page.title }} />
            <ContactForm />
          </HeroContent>
          <StyledImg
            fluid={illustrationContact.childImageSharp.fluid}
            alt=""
            loading="eager"
          />
        </Container>
      </HeroSection>
    </Layout>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        page: wordpressPage(path: {eq: "/contact/"}) {
          title
          content
          yoastMeta: yoast_meta {
            name
            property
            content
          }
          customMeta: et_custom_meta {
            title
            canonical
          }
        }
        illustrationContact: file(relativePath: { eq: "illustration-team.png" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => <Contact data={data} />}
  />
);
